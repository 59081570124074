//base styles
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  @include font;
  @include smooth;
  font-size: 16px;
  color: color(black);
	background-color: color(white);

  // Custom-cursor styles
  &.custom-cursor {
    a,
    button,
    .button {
      &,
      &:hover {
        cursor: none;
      }
    }
  }
}

a {
	color: inherit;
}

img {
  height: auto;
	max-width: 100%;
}

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  @include clearfix();
  padding: 0 $padding-sm;
  max-width: $sitewrapper;
  @include media-md {
    padding: 0 $padding-md;
  }
}

// Page Transitions
.transition-fade {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
html.is-animating .transition-fade {
  opacity: 0;
}