// Common Styles

// Split Headlines
.split-headline {
  @include h1;
  @include split-headline;
  line-height: 1;

  @include media-md {
    line-height: 1;
  }

  &.offset-top-1 {
    margin-bottom: -62px;
    transform: translateY(-62px);

    > span {
      &:first-of-type > span {
        padding-bottom: $beat;
      }
      &:last-of-type > span {
        padding-top: $beat;
      }
    }

    @include media-md {
      margin-bottom: beat(-14);
      transform: translateY(beat(-14));

      > span {
        &:first-of-type > span {
          padding-bottom: 0;
        }
        &:last-of-type > span {
          padding-top: $beat;
        }
      }
    }

    @include media-lg {
      margin-bottom: beat(-17);
      transform: translateY(beat(-17));

      > span {
        &:first-of-type > span {
          padding-bottom: $beat;
        }
        &:last-of-type > span {
          padding-top: 22px;
        }
      }
    }
  }
}

// Single Animated Headline
.section-headline.animate-in {
  overflow: hidden;

  > span {
    display: block;
    transform: translate3d(0,100%,0);
    transition: transform .5s ease-out;
  }

  // In-view
  &[data-in-view="true"] {
    > span {
      transform: translate3d(0,0,0);
    }
  }
}

// Common Section Styles
.section {
  position: relative;

  > .container {
    z-index: 1;
  }

  .section-line {
    top: 0;
    left: 50%;
    height: 100%;
    width: 70.3819vw;
    position: absolute;
    pointer-events: none;
    max-width: $sitewrapper;
    transform: translateX(-50%);

    svg {
      top: 0;
      left: 50%;
      height: 100%;
      width: 1.5786729%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  h1, h2, h3 {
    margin: 0;
  }

  .section-headline {
    @include h1;
  }

  .section-content,
  .long-text {
    p {
      margin: 0;

      + p {
        margin-top: $padding-sm;
      }
    }

    a {
      &:hover {
        color: color('teal');
      }
    }

    .col-md-1-2 + .col-md-1-2 {
      margin-top: $padding-sm;

      @include media-md {
        margin-top: 0;
      }
    }
  }

  .section-content {
    p {
      @include p1;
    }
  }

  .long-text {
    p {
      @include p2;
    }
  }

  hr {
    border: none;
    margin: beat(4) 0;
    border-top: 3px solid;
  }

  blockquote {
    margin: 0;
    padding-top: beat(3);
    border-top: 3px solid;

    p {
      margin: 0;
      @include h3;
    }

    @include media-md {
      padding-top: beat(6);
    }
  }

  .section-image {
    position: relative;
    margin: $padding-sm 0;

    img {
      display: block;
    }

    @include media-md {
      margin: $padding-md 0;
    }
  }

  .section-cta {
    margin: 0;

    &.overlap {
      transform: translateY(50%);
    }

    > *:not(.container) {
      margin: 0 auto;
    }

    @include media-md {
      > *:not(.container) {
        margin: 0;
      }
    }
  }
}

// Background Section
.background-section {
  height: 0;
  overflow: hidden;
  background-size: cover;
  padding-bottom: 111.1111%;
  background-position: center center;

  .background-container {
    top: 0;
    left: 50%;
    bottom: 0;
    z-index: 1;
    width: 70.3819vw;
    position: absolute;
    max-width: $sitewrapper;
    transform: translateX(-50%);
  }

  .y {
    left: 50%;
    width: 100%;
    bottom: beat(6);
    position: absolute;
    padding-bottom: 58.4435%;
    background-color: inherit;
    transform: translateX(-49.95%);
  }

  .y-stem {
    top: 0;
    left: 50%;
    height: 55%;
    width: 1.5786729%;
    position: absolute;
    transform: translateX(-52%);;
  }

  .logo-bottom {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include media-md {
    padding-bottom: 56.25%;

    .y {
      top: 0;
      bottom: auto;
    }

    .y-stem {
      display: none;
    }
  }
}