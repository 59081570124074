// Mixins

// Split Headline
@mixin split-headline($color1: color('black'), $color2: color('orange')) {
  > span {
    display: block;
    overflow: hidden;

    &:last-of-type > span {
      transition-delay: .25s;
    }

    > span {
      display: block;
      overflow: hidden;
      transition: transform .5s ease-out;
    }

    svg {
      width: 0.65em;
      height: 0.65em;
      display: inline-block;
    }

    &:first-of-type {
      color: $color1;
      > span {
        transform: translate3d(0,100%,0);
      }
    }
    &:last-of-type {
      color: $color2;
      > span {
        transform: translate3d(0,-100%,0);
      }
    }
  }

  // In-view
  &[data-in-view="true"] {
    > span > span {
      transform: translate3d(0,0,0);
    }
  }
}

// Ye olde clearfix
@mixin clearfix {
  &:after {
    content: '';
    clear: both;
    display: table;
  }
}

// Smooth text
@mixin smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Fancy Selection Styles
@mixin selection($text, $background) {
  *::-moz-selection {
    color: color('#{$text}');
    background-color: color('#{$background}');
  }
  *::selection {
    color: color('#{$text}');
    background-color: color('#{$background}');
  }
}

// Responsive mixins by Andy Richardson (@andimal): https://github.com/andimal/sass-responsive-mixin-madness
@mixin media-xs() {
  @media screen and (max-width: $breakpoint-sm - 1) {
    @content;
  }
}
@mixin media-sm() {
  @media screen and (min-width: $breakpoint-sm) {
    @content;
  }
}
@mixin media-md() {
  @media screen and (min-width: $breakpoint-md) {
    @content;
  }
}
@mixin media-lg() {
  @media screen and (min-width: $breakpoint-lg) {
    @content;
  }
}
@mixin media-nav() {
  @media screen and (min-width: $breakpoint-nav) {
    @content;
  }
}
@mixin media-xl() {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

// On-the fly media-queries
@mixin screen($res-min, $res-max) {
  @media screen and (min-width: $res-min) and (max-width: $res-max) {
    @content;
  }
}
@mixin max-screen($res) {
  @media screen and (max-width: $res - 1px) {
    @content;
  }
}
@mixin min-screen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}