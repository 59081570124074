// Events Grid
.events-grid-container {
  padding-top: 2px;
  overflow-x: hidden;
  position: relative;
  &::before,
  &::after {
    top: -2px;
    bottom: 0;
    z-index: 1;
    content: '';
    position: absolute;
    width: $padding-sm;
    background: color('white');
  }
  &::before {
    left: -2px;
  }
  &::after {
    right: -2px;
  }

  @include media-md {
    padding-top: 3px;
    &::before,
    &::after {
      top: -3px;
      width: $padding-md + 3px;
    }
    &::before {
      left: -3px;
    }
    &::after {
      right: -3px;
    }
  }
}

.events-grid {
  border-bottom: 2px solid color('black');

  @include media-md {
    border-bottom: 0;
    margin-left: -$padding-md;
    width: calc(100% + #{($padding-md * 2) + 9px});
  }

  .grid-item {
    width: 100%;
    // margin-top: -2px;
    padding: 20px 0 beat(6);
    background: color('white');
    border-top: 2px solid color('black');

    @include media-md {
      width: 50%;
      margin-top: -3px;
      margin-left: -3px;
      border: 3px solid color('black');
      padding: 20px $padding-md $padding-md;

      &:nth-of-type(2) {
        margin-top: 100px;
      }
    }
  }

  .event {
    a {
      &:hover {
        color: color('teal');
      }
    }

    .event-meta {
      p {
        @include h4;
        margin: 0;

        + p {
          margin-top: $beat;
        }
      }

      .event-type {
        svg {
          width: 0.75em;
          height: 0.75em;
          margin-left: 0.25em;
          display: inline-block;
        }
      }
    }

    .event-image {
      height: 0;
      margin: beat(2) 0;
      filter: grayscale(1);
      background-size: cover;
      padding-bottom: 57.1428%;
      background-repeat: no-repeat;
      background-position: center center;

      @include media-md {
        margin-bottom: beat(4);
      }
    }

    .event-title {
      @include p1;
      margin: 0;
    }

    .event-body {
      margin-top: beat(2);
      padding-top: beat(2);
      border-top: 2px solid color('black');

      @include media-md {
        margin-top: beat(4);
        padding-top: beat(4);
        border-top-width: 3px;
      }
    }

    .event-location {
      @include h4;
      margin: 0 0 beat(2);

      @include media-md {
        margin-bottom: beat(3);
      }
    }

    .event-description {
      @include p1;
      margin: 0;
    }

    .event-cta {
      margin: beat(3) 0 0;
    }

    @include media-md {
      .event-meta {
        display: flex;
        justify-content: space-between;

        p + p {
          margin-top: 0;
        }
      }
    }
  }
}

// Load More Queue
.load-more-queue {
  display: none;
}