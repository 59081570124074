// Riverwalk Page

body.riverwalk {
  @include selection(electric,forest);
  background-color: color('forest');

  // Active Nav Style
  .site-nav .nav-riverwalk a {
    color: color('black');
    border-bottom: 2px solid;
    svg {
      fill: color('black');
    }

    @include media-nav {
      color: color('white');
    }
  }
  .footer-nav .nav-riverwalk a {
    color: color('orange');
  }

  .site-logo svg {
    fill: color('electric');
  }

  .page-banner {
    background-color: color('forest');

    > .container {
      padding-bottom: beat(13);
    }

    .page-title {
      color: color('electric');
    }

    .banner-background {
      background-color: color('forest');
      svg {
        fill: color('moss');
      }
    }

    @include media-md {
      padding-bottom: beat(27);
    }
  }

  // Location Section
  .location-section {
    padding-bottom: $padding-sm;

    .headline-one {
      h2 {
        text-align: left;
      }
      p {
        @include p1;
        text-align: left;
      }
      @include media-md {
        .align-right {
          text-align: right;
        }
      }
    }

    .map {
      img {
        width: 100%;
        max-width: none;
      }
      margin: $padding-xl 0 $padding-sm;
    }

    @include media-md {
      padding-bottom: $padding-md;

      .map {
        margin: beat(20) 0 $padding-md;
      }
    }
  }

  // About the installation section
  .about-section {
    padding: beat(2) 0 $padding-sm;

    .about-intro {
      margin-bottom: $padding-md;
    }

    .headline-one {
      margin: 0 0 $padding-sm;
    }

    .installation-text {
      margin-bottom: $padding-sm;

      .col-md-1-2 + .col-md-1-2 {
        margin-top: $padding-sm;
      }
    }

    @include media-md {
      padding-bottom: $padding-md;

      .about-intro {
        margin-bottom: $padding-xl;
      }

      .headline-one {
        margin-bottom: $padding-md;
      }

      .installation-text {
        margin-bottom: $padding-md;

        .col-md-1-2 + .col-md-1-2 {
          margin-top: 0;
        }
      }
    }
  }

  // Design Team Section
  .design-team-section {
    padding: $padding-md 0;

    .headline-one {
      margin: 0 0 $padding-sm;
    }

    @include media-md {
      padding: $padding-xl 0;

      .headline-one {
        margin-bottom: $padding-md;
      }
    }
  }

  // Background-section
  .background-section {
    svg {
      fill: color('electric');
    }
  }
}