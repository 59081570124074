// Page Banner

html.is-changing {
  .page-banner {
    overflow: visible;
  }
}
.page-banner {
  z-index: 1;
  overflow: hidden;
  position: relative;
  padding-top: beat(23);
  transition: background-color .5s ease-out;

  > .container {
    z-index: 1;
    position: relative;
  }

  .page-title {
    @include h1;
    margin: 0;
    z-index: 1;
    text-align: right;
    position: relative;
  }

  .banner-background {
    top: 0;
    left: 50%;
    bottom: 0;
    z-index: 0;
    width: 70.3819vw;
    position: absolute;
    pointer-events: none;
    max-width: $sitewrapper;
    transform: translateX(-50%);
    transition: background-color .5s ease-out;

    svg {
      transition: fill .5s ease-out;
    }

    .y {
      left: 50%;
      z-index: 1;
      width: 100%;
      top: beat(6);
      position: absolute;
      padding-bottom: 58.4435%;
      transform: translateX(-50%);
    }

    .logo-top {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .y-stem {
      left: 50%;
      height: 2000px;
      top: 86%;
      width: 1.5786729%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .banner-content {
    p {
      @include p1;
      margin: 0;

      + p {
        margin-top: $padding-sm;
      }
    }
  }

  @include media-md {
    padding-top: beat(30);

    > .container {
      min-height: 40vw;
    }

    .banner-background {
      .y {
        top: beat(21);
      }
    }
  }
}