// Footer

.site-footer {
  padding: beat(10) 0 beat(5);
  background-color: color('white');

  > .container > .-top {
    margin-bottom: beat(11);
  }

  @include media-md {
    padding: $padding-lg 0 beat(2);

    > .container > .-top {
      margin-bottom: beat(17);
    }
  }

  a {
    color: color('orange');
    &:hover {
      color: color('teal');
    }
  }

  .newsletter-signup {
    h4 {
      margin-top: 0;
    }
    h4, p {
      @include p2;
    }
  }

  .contact-info {
    > div {
      p:first-of-type {
        margin-top: 0;
      }

      + div {
        margin-top: beat(4);
      }
    }
  }
}

// Newsletter Form
form.newsletter {
  padding-top: $beat;

  .zipcode {
    padding-right: 0;
  }

  button.submit {
    color: color('orange');
    margin-bottom: beat(5);
    &:hover {
      color: color('teal');
    }
  }
}

// Footer Navigation
.footer-nav {
  ul {
    @extend .semantic-list;

    > div {
      &.-bottom {
        margin-top: beat(4);
        padding-top: beat(2);
        border-top: 1px solid color('black');
      }
    }

    li {
      padding-right: $beat;
    }

    li + li {
      margin-top: beat(2);
    }

    a {
      &:hover,
      &:focus {
        color: color('teal');
      }
      &:not(.privacy-policy) {
        color: color('black');
        text-decoration: none;
        &:hover,
        &:focus {
          color: color('teal');
          text-decoration: underline;
        }
      }
    }

    @include media-md {
      > div {
        display: flex;
        justify-content: space-between;
      }

      li + li {
        margin-top: 0;
      }
    }
  }
}