// Design Impact Grants Page

body.design-impact-grants {
  @include selection(teal,cream);
  background-color: color('cream');

  // Active Nav Style
  .site-nav .nav-design-impact-grants a {
    color: color('black');
    border-bottom: 2px solid;
    svg {
      fill: color('black');
    }

    @include media-nav {
      color: color('white');
    }
  }
  .footer-nav .nav-design-impact-grants a {
    color: color('orange');
  }

  .site-logo svg {
    fill: color('teal');
  }

  .page-banner {
    background-color: color('cream');

    > .container {
      padding-bottom: $padding-sm;
    }

    .page-title {
      color: color('teal');
    }

    .banner-background {
      background-color: color('cream');
      svg {
        fill: color('white');
      }
    }

    @include media-md {
      > .container {
        padding-bottom: $padding-md;
      }
    }
  }

  // Intro Section
  .intro-section {
    padding: beat(2) 0 0;

    &.grantees-shown {
      padding-bottom: $padding-md;

      @include media-md {
        padding-bottom: beat(39);
      }
    }
  }

  .grantees-section {
    padding: $padding-md 0;

    .headline-one {
      margin-bottom: $padding-md;
    }

    @include media-md {
      padding: beat(26) 0 $padding-xl;

      .headline-one {
        margin-bottom: beat(14);
      }
    }
  }

  // Background-section
  .background-section {
    svg {
      fill: color('teal');
    }
  }
}