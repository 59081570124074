// Header

.site-header {
  top: 0;
  z-index: 2;
  width: 100%;
  position: absolute;

  > .container {
    padding: 0;
  }

  .site-logo {
    margin: 0;
    z-index: 2;
    left: 20px;
    opacity: 1;
    top: beat(2);
    position: absolute;
    transition: opacity .5s ease-out;

    a {
      display: inline-block;
      &:hover {
        svg {
          fill: color('white');
        }
      }
    }

    svg {
      width: 230px;
      height: 167px;
      vertical-align: top;
      transition: fill .2s ease-out;
    }
  }

  @include media-md {
    .site-logo {
      top: 43px;
    }
  }
}

// Nav Toggle Buttons
.nav-open,
.nav-close {
  @extend .button-reset;
  padding: 0;
  cursor: pointer;
  position: absolute;

  svg {
    display: block;
    fill: color('black');
  }

  @include media-nav {
    display: none;
  }
}

.nav-open {
  top: 17px;
  right: 20px;
  padding: 15px 5px;
  svg {
    width: 50px;
    height: 18px;
  }
}

.nav-close {
  top: 15px;
  right: 25px;
  padding: 5px;
  svg {
    width: 40px;
    height: 40px;
  }
}

.site-nav {
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 100vh;
  display: none;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(color('gray'),0.96);
  padding: $padding-lg $padding-sm $padding-sm;

  &.-active {
    display: flex;
  }

  ul {
    @extend .semantic-list;

    li {
      a {
        @include paragraph-style(34, 40);
        color: color('orange');
        text-decoration: none;

        svg {
          width: 0.75em;
          height: 0.75em;
          fill: color('orange');
        }
      }

      + li {
        margin-top: beat(3);
      }
    }
  }

  .utility-nav {
    margin-top: beat(4);
    padding: beat(2) 0 beat(4);
    border-top: 2px solid color('black');

    .neocon-link {
      text-decoration: none;

      svg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  }

  @include media-md {
    ul {
      li {
        a {
          @include paragraph-style(80, 80);
        }
      }
    }
  }

  @include media-nav {
    z-index: 0;
    height: auto;
    display: block;
    position: absolute;
    padding: beat(5) 0 0 269px;
    background-color: transparent;

    a {
      transition: color .2s ease-out, padding .2s ease-out;
      &:hover {
        color: color('white');
      }
    }

    ul {
      li {
        display: inline-block;
        + li {
          margin-top: 0;
          margin-left: beat(5);
        }

        a {
          @include button(p2);
          padding-bottom: 5px;
          color: color('black');
          &:hover {
            padding-bottom: 8px;
            color: color('white');
          }
        }

        svg {
          display: none;
        }

        &.nav-home {
          display: none;
        }
      }
    }

    .utility-nav {
      top: 25px;
      margin: 0;
      padding: 0;
      right: 25px;
      border: none;
      position: absolute;
    }
  }
}